import { AppContext, AppProps } from 'next/app'
import React, { useCallback, useEffect, useState } from 'react'
import './../styles/mixin.scss'
import "./../styles/globals.css";


function MyApp({ Component, pageProps }: AppProps) {
  const [isH5, setIsH5] = useState<boolean>(pageProps.isMobile)
  const handleResize = useCallback(() => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    setIsH5(width <= 800)
  }, [])
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize, { passive: true })
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])
  return (
    <Component {...pageProps} isH5={isH5} />
  )
}
MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  const userAgent = ctx.req?.headers['user-agent']
  let isMobile = false;
  const mobileKeywords = ["Android", "iPhone", "iPad", "Windows Phone"];
  mobileKeywords.forEach(keyword => {
    if (userAgent?.includes(keyword)) {
      isMobile = true;
      return
    }
  });
  let pageProps = {} as any;
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }
  pageProps.isMobile = isMobile
  return { pageProps }
}

export default MyApp